.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.column {
  flex: 1;
/*  border: 1px solid #ccc; /* For visualization */
  padding: 10px;
  text-align: center;
}

.hidden {
  visibility: hidden
}
.visible {
  visibility: visible
}
.wrong {
  color :rgb(170, 4, 4)
}
.correct {
  color :rgb(0, 170, 0)
}